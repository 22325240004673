import { useIsInView } from "helpers/useInView";
import { useEffect, useRef, useState } from "react";
import { animate, motion } from "framer-motion";
import useResponsive from "helpers/useResponsive";
import styled from "styled-components";
import HueIcon from "assets/images/hue.png";
import { useQuery } from "react-query";
import axios from "axios";
import Spinner from "components/Spinner";

const seperator = require("assets/images/seperator.svg");
const mobileSeperator = require("assets/images/mobile-seperator.svg");

const apiURLEndpoint = process.env.REACT_APP_API_URL;

const RTLScroll = styled.div`
  display: flex;
  gap: 7rem;
  animation: rightToLeft 240s linear infinite;
  overflow-x: hidden;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  width: fit-content;
  z-index: 2;
  @keyframes rightToLeft {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

export default function About() {
  const [ref, isInView] = useIsInView();
  const { isTablet, isMobile } = useResponsive();
  const { data, isLoading } = useQuery("about", () =>
    axios.get(`${apiURLEndpoint}/configs`).then((res) => res.data)
  );

  return (
    <section
      id="about"
      className="grid gap-4 md:gap-[5rem] justify-items-center md:mt-[5rem]"
    >
      <motion.div
        className="grid gap-[2rem] text-center mb-[2.5rem] relative"
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        <span className="font-sans text-[1.5rem] font-[600] uppercase px-4 z-[2]">
          Since 2017: Our Strategic Model Has Performed
        </span>

        {isTablet ? (
          <div className="px-4 z-[2]">
            <div className="shadow-shadowAboutContainer rounded-[24px]">
              <div className="flex gap-4">
                <div className="w-1/2 shadow-shadowAboutLandingMobile !justify-center grid mx-auto self-center py-[1.875rem] justify-between bg-[white] shadow-landingPage rounded-[12px]">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <Card
                      maxNumber={data[0].totalSelections.toString()}
                      title="TOTAL SELECTIONS"
                      isInView={isInView}
                      isMobile={isTablet}
                    />
                  )}
                </div>
                {/* <mobileSeperator.ReactComponent className="justify-self-center" /> */}
                <div className="w-1/2 shadow-shadowAboutLandingMobile !justify-center grid mx-auto self-center py-[1.875rem] justify-between bg-[white] shadow-landingPage rounded-[12px]">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <Card
                      maxNumber={data[0].wins.toString()}
                      title="Wins"
                      isInView={isInView}
                      isMobile={isTablet}
                    />
                  )}
                </div>
              </div>
              {/* <mobileSeperator.ReactComponent className="justify-self-center" /> */}
              <div className="mt-4 shadow-shadowAboutLandingMobile !justify-center grid mx-auto self-center py-[1.875rem] justify-between bg-[white] shadow-landingPage rounded-[12px]">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Card
                    maxNumber={data[0].winPercentage.toString() + "%"}
                    title="Win Percentage"
                    isInView={isInView}
                    isMobile={isTablet}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="z-[2] justify-between bg-[white] shadow-landingPage lg:px-[3.125rem] md:px-4 lg:py-[1.25rem] rounded-[150px] flex items-center max-w-[100vw] w-[1100px]">
            {isLoading ? (
              <Spinner />
            ) : (
              <Card
                maxNumber={data[0].totalSelections.toString()}
                title="TOTAL SELECTIONS"
                isInView={isInView}
              />
            )}
            <seperator.ReactComponent />
            {isLoading ? (
              <Spinner />
            ) : (
              <Card
                maxNumber={data[0].wins.toString()}
                title="Wins"
                isInView={isInView}
              />
            )}
            {/* <seperator.ReactComponent /> */}
            {/* <Card maxNumber="177" title="Losses" isInView={isInView} /> */}
            <seperator.ReactComponent />
            {isLoading ? (
              <Spinner />
            ) : (
              <Card
                maxNumber={data[0].winPercentage.toString() + "%"}
                title="Win Percentage"
                isInView={isInView}
              />
            )}
          </div>
        )}

        {!isTablet && (
          <img
            src={HueIcon}
            alt="Hue Icon"
            className="absolute -top-[12.5rem] z-[1]"
          />
        )}
      </motion.div>

      <div
        style={{
          width: "100%",
          overflowX: "hidden",
          boxSizing: "border-box",
          zIndex: 2,
        }}
      >
        <RTLScroll>
          {
            // repeat 10 times
            new Array(30).fill(0).map((_, i) => (
              <p
                key={i}
                className="z-[2] font-monument text-primary text-[#EBF0EB] font-black font-montserrat uppercase xl:text-[7.5rem] lg:text-[3.75rem] md:text-[3rem] text-[3rem] font-[900]"
              >
                We pick U play
              </p>
            ))
          }
        </RTLScroll>
      </div>

      <div className="grid text-center gap-[1.5rem]">
        <motion.h2
          className="px-4 title font-bold uppercase md:text-[3rem] text-[2rem] font-[500] max-w-[1000px] text-center mt-[4.5rem] text-[#373837]"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          A new era in sports betting
        </motion.h2>
        <motion.p
          className="max-w-[40rem] text-center text-black md:text-[1.25rem] text-[1rem] leading-[170%] mx-auto md:px-0 px-4"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          At the heart of WePickUPlay lies a groundbreaking mission: to disrupt
          the traditional sports betting industry with our advanced algorithmic
          solutions. We're not just about predictions; we're about
          revolutionizing the way you wager. By eliminating human emotion and
          bias, we focus solely on data-driven decisions, dramatically reducing
          risk and enhancing win probability. Join us in our journey to redefine
          intelligent sports wagering and experience betting like never before.
        </motion.p>
      </div>
    </section>
  );
}

function Card({
  maxNumber,
  title,
  isInView,
  isMobile,
}: {
  maxNumber: string;
  title: string;
  isInView?: boolean;
  isMobile?: boolean;
}) {
  if (isMobile) {
    return (
      <div className="grid gap-3 text-center">
        <span className="text-center text-[1.75rem] md:text-[2rem] font-bold font-sans uppercase leading-[120%]">
          {maxNumber.includes("%") ? (
            <div className="flex !justify-center text-[#333333]">
              <Counter from={0} to={isInView ? parseFloat(maxNumber) : 0} />%
            </div>
          ) : (
            <Counter from={0} to={isInView ? parseInt(maxNumber) : 0} />
          )}
        </span>
        <span className="text-[1rem] md:text-[1rem] uppercase">{title}</span>
      </div>
    );
  }
  return (
    <div className="w-1/3 grid gap-3 py-[3rem] lg:px-[3.125rem] md:px-[1.5rem] md:w-[16.875rem] text-center">
      <span className="text-center md:max-lg:text-[2rem] text-[2.5rem] font-bold font-sans uppercase leading-[120%]">
        {maxNumber.includes("%") ? (
          <div className="flex !justify-center text-[#333333]">
            <Counter from={0} to={isInView ? parseFloat(maxNumber) : 0} />%
          </div>
        ) : (
          <Counter from={0} to={isInView ? parseInt(maxNumber) : 0} />
        )}
      </span>
      <span className="md:max-lg:text-[1rem] text-[1.125rem] uppercase">
        {title}
      </span>
    </div>
  );
}
function Counter({ from, to }: { from: number; to: number }) {
  const nodeRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        if (node && Number.isInteger(to)) {
          node.textContent = value.toFixed(0);
        } else if (node && !Number.isInteger(to)) {
          node.textContent = value.toFixed(2);
        }
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return <p className="font-monument text-[#333333]" ref={nodeRef} />;
}
